<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0 pt-0">
        <trev-job-reports-quick-links-bar></trev-job-reports-quick-links-bar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <trev-jobs-table :isGlobal="true" :hideInvoiceStatus="true" :hideStatus="true" :defaultInvoiceSentStatus="'0'"
          :defaultStatus="'Open'">
        </trev-jobs-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import trevJobsTable from '../../../../components/jobs/trev-jobs-table.vue'
import TrevJobReportsQuickLinksBar from '../../../../components/reports/trev-job-reports-quick-links-bar.vue'
export default {
  components: { trevJobsTable, TrevJobReportsQuickLinksBar },

}
</script>

<style>

</style>